import { idID } from "@mui/material/locale";
import { LeadDrawerDetails } from "../types/type";

export const TABLABELS: string[] = [
  "Customer Details",
  "Insurance Scope",
  "Materials",
  "Contractors",
  "Estimate",
  "Installation Details",
  "Payment Details",
  "Documents",
  "COC / Invoice",
];

export const LEAD_DRAWER_DETAILS: LeadDrawerDetails[] = [
  { label: "First Name", key: "firstName", isDate: false },
  { label: "Last Name", key: "lastName", isDate: false },
  { label: "Email", key: "email", isDate: false },
  { label: "Phone", key: "phone", isDate: false },

  { label: "Street Address", key: "streetAddress", isDate: false },
  { label: "Unit", key: "unit", isDate: false },
  { label: "City", key: "city", isDate: false },
  { label: "State", key: "state", isDate: false },
  { label: "Zip Code", key: "zipCode", isDate: false },

  { label: "Roof Condition", key: "roofCondition", isDate: false },
  { label: "Type of Damage", key: "typeOfDamaged", isDate: false },
  { label: "Date of Damage", key: "dateOfDamaged", isDate: true },

  { label: "Ref Id", key: "refId", isDate: false },

  { label: "UTM", key: "utm", isDate: false },
  { label: "Created At", key: "createdAt", isDate: true },
  { label: "Updated At", key: "updatedAt", isDate: true },
];

export const INSURANCE_SCOPE_DETAILS: any = {
  id: 0,
  approved: false,
  materialMeasurements: [
    {
      productId: 12,
      name: "Fench",
      measurements: [
        {
          id: 1,
          name: "gold",
          unit: "mg",
          parents: [],
          categoryId: 1,
          createdAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          updatedAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          value: {
            materialId: 1,
            quantity: 10,
          },
        },
        {
          id: 1,
          name: "gold 2",
          unit: "mg",
          parents: [],
          categoryId: 1,
          createdAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          updatedAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          value: {
            materialId: 1,
            quantity: 10,
          },
        },
      ],
    },
    {
      productId: 13,
      name: "duration",
      measurements: [
        {
          id: 2,
          name: "duration",
          unit: "hours",
          parents: [],
          categoryId: 1,
          createdAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          updatedAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          value: {
            materialId: 2,
            quantity: 2,
          },
        },
      ],
    },
    {
      productId: 1,
      name: "Fench",
      measurements: [
        {
          id: 3,
          name: "gold",
          unit: "mg",
          parents: [],
          categoryId: 1,
          createdAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          updatedAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          value: {
            materialId: 3,
            quantity: 5,
          },
        },
      ],
    },
    {
      productId: 4,
      name: "te",
      measurements: [
        {
          id: 4,
          name: "te",
          unit: "kg",
          parents: [],
          categoryId: 1,
          createdAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          updatedAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          value: {
            materialId: 4,
            quantity: 8,
          },
        },
      ],
    },
    {
      productId: 6,
      name: "u",
      measurements: [
        {
          id: 5,
          name: "u",
          unit: "m",
          parents: [],
          categoryId: 1,
          createdAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          updatedAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          value: {
            materialId: 5,
            quantity: 12,
          },
        },
      ],
    },
    {
      productId: 5,
      name: "me",
      measurements: [
        {
          id: 6,
          name: "me",
          unit: "kg",
          parents: [],
          categoryId: 1,
          createdAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          updatedAt: "2024-04-10 13:30:47.402083 +0000 UTC",
          value: {
            materialId: 6,
            quantity: 15,
          },
        },
      ],
    },
  ],
  laborMeasurements: [],
  paymentDetails: [],
  insuranceClaim: [],
  addons: [],
};

export const PAYMENT_DRAWER_DETAILS: any[] = [
  { label: "ID", key: "id", isDate: false },
  { label: "Project ID", key: "projectId", isDate: false },
  { label: "Step", key: "step", isDate: false },
  { label: "Title", key: "title", isDate: false },
  { label: "Description", key: "description", isDate: false },
  { label: "Remarks", key: "remarks", isDate: false },
  { label: "Amount", key: "amount", isDate: false },
  { label: "Payment Log", key: "paymentLog", isDate: false },
  { label: "Transaction ID", key: "transactionId", isDate: false },
  { label: "PG Status", key: "pgStatus", isDate: false },
  { label: "Payment Type", key: "paymentType", isDate: false },
  { label: "Email", key: "email", isDate: false },
  { label: "Approved", key: "approved", isDate: false },
  { label: "Completed", key: "completed", isDate: false },
  { label: "Created At", key: "createdAt", isDate: true },
  { label: "Updated At", key: "updatedAt", isDate: true },
];

export const SELECT_FILETYPE = [
  {
    value: "DOCUMENT",
    title: "DOCUMENT",
  },
  {
    value: "LICENSE",
    title: "LICENSE",
  },
  {
    value: "PHOTO",
    title: "PHOTO",
  },
  {
    value: "INSURANCE",
    title: "INSURANCE",
  },
  {
    value: "INVOICE",
    title: "INVOICE",
  },
  {
    value: "COC",
    title: "COC",
  },
];
