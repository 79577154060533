import { FC, useEffect, useState } from "react";
import { ColDiv } from "../../../../components/misc/misc.styled";
import { ProjectProductMeterial } from "../../../../redux/types/Material";
import { useFetchProjectDetails } from "../../../../apolloClient/hooks/general/fetchHook";
import { useParams } from "react-router-dom";
import { ContractorTable } from "./ContractorTable";

interface MaterialProps {
  materials?: ProjectProductMeterial[]; // Optional prop
}

export const ContractorDetails: FC<MaterialProps> = ({ materials }) => {
  const [materialList, setMaterialList] = useState<ProjectProductMeterial[]>(
    materials || [] // Initialize with an empty array if materials is undefined
  );


  let { id } = useParams();

  const {
    projectDetails: projectDetailsData,
    loading: projectDetailsLoading,
    error: projectDetailsError,
    refetch,
  } = useFetchProjectDetails({
    projectId: Number(id),
  });

  // Trigger the refetch when the id changes (if needed)
  useEffect(() => {
    if (id) {
      refetch(); // Call refetch to make sure the query is updated when the id changes
    }
  }, [id, refetch]);

  const labourMeasurements =
    projectDetailsData?.insuranceScope?.laborMeasurements;

  return (
    <>
      <ColDiv
        style={{
          width: "95%",
          marginBottom: "1%",
          gap: "10px",
          overflowX: "hidden",
        }}
      >
        {projectDetailsLoading ? (
          <div className="text-center flex items-center justify-center text-gray-700 h-[200px]">
            Loading...
          </div>
        ) : (
          <div className="flex w-full flex-col justify-start items-start gap-4 pb-2">
            {labourMeasurements && labourMeasurements.length === 0 ? (
              <div className="text-center text-gray-500">
                No data to display.
              </div>
            ) : (
              <ContractorTable labourMeasurements={labourMeasurements} />
            )}
          </div>
        )}
      </ColDiv>
    </>
  );
};
