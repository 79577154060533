import * as React from "react";
import { Spacer } from "../../../../components";
import { Container } from "../../../../globalstyled";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

interface ContractorTableProps {
  labourMeasurements: any[];
}

const truncateText = (text: string, length: number) => {
  if (!text) return ""; // Return empty string if no text
  return text.length > length ? text.substring(0, length) + "..." : text;
};

export const ContractorTable: React.FC<ContractorTableProps> = ({
  labourMeasurements,
}) => {
  const Table = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            width: "95%",
            marginBottom: "1%",
            gap: "10px",
            overflowX: "hidden",
          }}
        >
          {labourMeasurements?.some((labourCategory) => {
            const firstMeasurement = labourCategory?.measurements[0];
            const selectedContractor =
              firstMeasurement?.associatedContractor?.filter(
                (contractor: any) => contractor?.isSelected === true
              );
            return selectedContractor?.length > 0;
          }) ? (
            labourMeasurements?.map((labourCategory, index) => {
              const firstMeasurement = labourCategory?.measurements[0];

              const selectedContractor =
                firstMeasurement?.associatedContractor?.filter(
                  (contractor: any) => contractor?.isSelected === true
                );

              if (selectedContractor?.length === 0) {
                return null;
              }

              return (
                <div key={index}>
                  {selectedContractor?.length > 0 && (
                    <>
                      <h2 className="underline underline-offset-2 mb-3 font-bold text-center text-xl">
                        {labourCategory?.name}
                      </h2>
                      <table
                        className="table-auto"
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead className="bg-gray-200">
                          <tr>
                            <th className="font-semibold py-2 text-gray-700">
                              Name
                            </th>
                            <th className="font-semibold py-2 text-gray-700">
                              Description
                            </th>
                            <th className="font-semibold py-2 text-gray-700">
                              Website
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-gray-50">
                          {selectedContractor?.map((labour, idx) => (
                            <tr
                              key={idx}
                              className="border-t hover:bg-gray-100"
                            >
                              <td className="text-center py-2 text-gray-800">
                                {labour?.name || "N/A"}
                              </td>
                              <td className="text-center py-2 text-gray-800">
                                {truncateText(labour?.description, 30)}
                              </td>
                              <td className="text-center py-2 text-gray-800">
                                {labour?.website}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Spacer size={2} direction="col" />
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <p className="text-center text-gray-500">No data available</p>
          )}
        </ColDiv>
      </Container>
    );
  };

  return Table();
};
