import { gql, DocumentNode } from "@apollo/client";

export const GET_ADMIN_USERS: DocumentNode = gql`
  query GetAdminUsers($limit: Int!, $offset: Int!) {
    adminUsers(limit: $limit, offset: $offset) {
      id
      email
      firstName
      lastName
      phone
      Status
      role
    }
  }
`;
export const LOGIN_ADMIN_USER: DocumentNode = gql`
  query LoginAdminUser($email: String!, $password: String!) {
    adminLogin(input: { email: $email, password: $password }) {
      token
    }
  }
`;

export const GET_ADMIN_USER_BY_ID = gql`
  query GetAdminUserById($id: Int!) {
    adminUser(id: $id) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  query GetUploadUrl {
    getUploadUrl {
      url
    }
  }
`;

export const GET_PROJECTS = gql`
  query GetProjects($limit: Int!, $offset: Int!) {
    projects(limit: $limit, offset: $offset) {
      id
      customerId
      projectRefNumber
      customer {
        id
        firstName
        lastName
        email
        phone
        address {
          id
          street
          zipCode
          city
          state
        }
      }
      progress {
        id
        projectId
        approved
        remarks
        title
        description
        completed
        step
      }
      products {
        id
        name
        thumbnail
        duration
        priority
      }
      files {
        url
        type
        id
        projectId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      paymentType
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query GetProjectById($id: Int!) {
    project(id: $id) {
      id
      customerId
      customer {
        id
        email
        phone
        firstName
        lastName
        verified
        createdAt
        updatedAt
        leads {
          id
          roofCondition
          dateOfDamage
          typeOfDamaged
        }
        address {
          id
          userId
          street
          zipCode
          city
          state
          lat
          lng
        }
        projectId
      }
      progress {
        id
        projectId
        approved
        remarks
        title
        description
        completed
        step
      }
      products {
        id
        name
        thumbnail
        duration
        priority
      }
      files {
        url
        type
        id
        projectId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      paymentType
    }
  }
`;

export const GET_LEADS = gql`
  query GetLeads($limit: Int!, $offset: Int!, $refId: Int) {
    leads(limit: $limit, offset: $offset, refId: $refId) {
      id
      uuid
      streetAddress
      unit
      zipCode
      city
      state
      roofCondition
      dateOfDamage
      typeOfDamaged
      firstName
      lastName
      phone
      email
      utm
      refId
      customerReferralName
      createdAt
      updatedAt
    }
  }
`;

export const GET_LEAD_BY_ID = gql`
  query GetLeadById($id: Int!) {
    lead(id: $id) {
      id
      uuid
      streetAddress
      unit
      zipCode
      city
      state
      roofCondition
      dateOfDamage
      typeOfDamaged
      firstName
      lastName
      phone
      email
      utm
      refId
      customerReferralName
      createdAt
      updatedAt
    }
  }
`;

export const GET_WAIT_LIST = gql`
  query GetWaitLists($limit: Int!, $offset: Int!) {
    waitList(limit: $limit, offset: $offset) {
      id
      uuid
      streetAddress
      unit
      zipCode
      city
      state
      roofCondition
      dateOfDamage
      typeOfDamaged
      firstName
      lastName
      phone
      email
      utm
      refId
      customerReferralName
      createdAt
      updatedAt
    }
  }
`;

export const GET_INSURANCE_COMPANIES = gql`
  query GetInsuranceCompanies($limit: Int!, $offset: Int!) {
    insuranceCompanies(limit: $limit, offset: $offset) {
      id
      companyName
      companyLogo
      claimLink
      createdAt
      updatedAt
    }
  }
`;

export const GET_INSURANCE_COMPANY_BY_ID = gql`
  query GetInsuranceCompanyById($id: Int!) {
    insuranceCompany(id: $id) {
      id
      companyName
      companyLogo
      claimLink
      createdAt
      updatedAt
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query GetCustomers($limit: Int!, $offset: Int!) {
    customers(limit: $limit, offset: $offset) {
      id
      email
      password
      phone
      firstName
      lastName
      resetCode
      expiry
      verified
      createdAt
      updatedAt
      leads {
        id
        uuid
        streetAddress
        unit
        zipCode
        city
        state
        roofCondition
        dateOfDamage
        typeOfDamaged
        firstName
        lastName
        phone
        email
        utm
        refId
        customerReferralName
        createdAt
        updatedAt
      }
      address {
        id
        userId
        street
        zipCode
        city
        state
        lat
        lng
      }
      projectId
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query GetCustomerById($id: Int!) {
    customer(id: $id) {
      id
      email
      phone
      firstName
      lastName
      verified
      createdAt
      updatedAt
      leads {
        id
        uuid
      }
      address {
        id
        userId
        street
        zipCode
        city
        state
        lat
        lng
      }
      projectId
    }
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query ProjectDetails($projectId: Int!) {
    projectDetails(projectId: $projectId) {
      projectId
      customerDetails {
        id
        email
        phone
        firstName
        lastName
        verified
        createdAt
        updatedAt
        address {
          street
          city
          zipCode
          state
        }
      }
      insuranceScope {
        id
        projectId
        approved
        materialMeasurements {
          productId
          name
          measurements {
            id
            unit
            name
            parents {
              id
            }
            associatedMaterials {
              id
              name
              measurementId
              brand
              description
              price
              quality
              measurementUnit
              coverageAmount
              isSelected
            }
            value {
              materialId
              quantity
            }
            categoryId
            createdAt
            updatedAt
          }
        }
        laborMeasurements {
          name
          productId
          measurements {
            id
            name
            unit
            parents {
              id
            }
            value {
              materialId
              quantity
            }
            associatedContractor {
              id
              name
              website
              description
              isSelected
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
        paymentDetails {
          id
          projectId
          step
          approved
          title
          description
          remarks
          amount
          paymentLog
          transactionId
          pgStatus
          completed
          paymentType
          email
          createdAt
          updatedAt
        }
        insuranceClaim {
          id
          projectId
          companyName
          Insurer
          ClaimNumber
          ClaimDate
          TotalPayout
          FirstCheck
          SecondCheck
          Approved
        }
        addOns {
          id
          projectId
          addonName
          description
          price
          createdAt
          updatedAt
        }
      }
      installationDetails {
        id
        projectId
        installationDate
      }
      paymentDetails {
        id
        projectId
        step
        approved
        title
        description
        remarks
        amount
        paymentLog
        transactionId
        pgStatus
        completed
        paymentType
        email
        createdAt
        updatedAt
      }
      documents {
        id
        projectId
        url
        type
        createdAt
        updatedAt
      }
      cocAndInvoice {
        id
        projectId
        url
        type
        createdAt
        updatedAt
      }
      progress {
        id
        step
        projectId
        title
        description
        remarks
        approved
        completed
        payment {
          id
          name
          paymentType
          percent
          amount
          stepNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MATERIALS_BY_CATEGORY = gql`
  query MaterialsByCategory($id: Int!) {
    materialsByCategory(id: $id) {
      id
      name
      measurement {
        id
        name
        unit
        parents {
          id
        }
        categoryId
        createdAt
        updatedAt
      }
      brand
      description
      price
      quality
      measurementUnit
      coverageAmount
      images {
        id
        url
        type
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_MATERIAL_MEASUREMENTS_FOR_PROJECT = gql`
  query materialMeasurements($limit: Int!, $offset: Int!) {
    materialMeasurements(limit: $limit, offset: $offset) {
      id
      name
      unit
      parents {
        id
        name
      }
      categoryId
    }
  }
`;

export const GET_LABOR_MEASUREMENTS_FOR_PROJECT = gql`
  query laborMeasurements($limit: Int!, $offset: Int!) {
    laborMeasurements(limit: $limit, offset: $offset) {
      id
      name
      unit
      parents {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_PRODUCTS = gql`
  query project($id: Int!) {
    project(id: $id) {
      id
      products {
        id
        name
      }
    }
  }
`;
